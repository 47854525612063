
					@use 'sass:math';
					@import "@/styles/globals.scss";
					

/* stylelint-disable */
.wrapper {
	color: $color-primary;
	.terms-n-conditions {
		max-height: 60vh;
		overflow-y: scroll;
	}
}
.footer {
	border-top: 1px solid $color-primary;
	padding: 2rem 0;
	.action-buttons {
		display: flex;
		justify-content: flex-end;
		gap: 1rem;
	}
}

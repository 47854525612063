
					@use 'sass:math';
					@import "@/styles/globals.scss";
					

.terms-n-conditions {
	ul,
	ol {
		margin: 0 0 20px 40px;
	}
	li {
		margin-bottom: 10px;
	}
	p {
		margin: 10px 0;
	}

	h1 {
		@include heading-3;
	}
	h2 {
		@include heading-4;
		padding: 1.5rem 0;
	}
}

.contact-info {
	margin-top: 20px;
}

.list-dots {
	list-style-type: disc;
}
.list-alphabetic {
	list-style-type: lower-alpha;
}

.list-ordered {
	list-style-type: decimal;
}

.padded-top {
	padding-top: 1rem;
}


					@use 'sass:math';
					@import "@/styles/globals.scss";
					

:root {
	--header-height: 11rem;

	@include max-lg-and-portrait {
		--header-height: 9.2rem;
	}
}

.app-header {
	display: grid;
	grid-template-columns: repeat(24, 1fr);
	grid-template-rows: 1fr;
	max-width: 100vw;
	height: $header-height;
	padding: 0.5rem 4.8rem;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 10000;
	background: $color-white;
	gap: 0 2.4rem;

	@include max-lg-and-portrait {
		padding: 0.5rem 3rem;
		gap: 0 2rem;
	}

	&__logo {
		display: flex;
		grid-column: 1 / span 2;
		align-self: center;
		min-width: 5.6rem;
		outline: 0;

		@include max-lg-and-portrait {
			min-width: 4.4rem;
		}
	}

	&__logo-basic {
		width: 5.6rem;
		height: 5.6rem;

		@include max-lg-and-portrait {
			width: 4.4rem;
			height: 4.4rem;
		}
	}

	&__logo-simplified {
		width: 4.8rem;
		height: 4.8rem;

		@include max-lg-and-portrait {
			width: 4.4rem;
			height: 4.4rem;
		}
	}

	&__menu-wrapper {
		display: flex;
		grid-column: 24 / span 1;
		align-items: center;
		position: relative;
	}

	&__user-menu {
		right: 0;

		&.base-dropdown {
			position: absolute;
		}
	}

	&--simplified {
		justify-content: flex-start;
		background: transparent;
	}
}


					@use 'sass:math';
					@import "@/styles/globals.scss";
					

.app-toast {
	display: flex;
	align-items: center;
	min-height: 8rem;
	position: fixed;
	right: 4.8rem;
	bottom: 3.2rem;
	left: 4.8rem;
	z-index: 10000;
	color: $color-light;

	&__backdrop {
		@include dropshadow-selected;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		border-radius: 1.6rem;
		background: $color-gray;
	}

	&__content {
		display: flex;
		align-items: center;
		width: 100%;
		padding: 1.6rem 1.6rem 1.6rem 2.5rem;
		position: relative;
		z-index: 10;
		font-size: $font-size-h3;
	}

	&__message {
		margin-right: 4rem;
	}

	&__action {
		display: flex;
		align-items: center;
		margin-left: auto;
	}

	&__close-button {
		width: 4.8rem;
		height: 4.8rem;
		color: $color_light;
		border-color: $color_light;
	}

	.base-button {
		height: 4.8rem;
		margin-left: 2.4rem;
		color: $color_light;
		border-color: $color_light;

		&:hover {
			border-color: $color-dark;
		}
	}
}


					@use 'sass:math';
					@import "@/styles/globals.scss";
					

.base-menu-list {
	position: relative;

	&__list {
		line-height: 1.28;
	}

	&__label {
		display: block;
		margin-bottom: 0.5rem;
		color: #666;
		font-size: 0.8em;
		font-weight: 500;
	}

	& + & {
		margin-top: 1.5rem;
		padding-top: 1.5rem;

		&::before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			border-top: 1px solid #eaeaea;
		}
	}
}

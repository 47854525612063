
					@use 'sass:math';
					@import "@/styles/globals.scss";
					

.view-forgot-password {
	display: flex;
	align-items: center;

	&__form {
		@include max-md-and-portrait {
			display: grid;
			grid-template-columns: repeat(12, 1fr);
		}

		.form__header {
			grid-column: 1 / span 4;

			@include max-md-and-portrait {
				padding: 0;
			}
		}

		.form__content {
			grid-column: 7 / span 6;
			padding: 1.5rem 0;

			@include max-md-and-portrait {
				padding: 0;
			}
		}
	}
}


					@use 'sass:math';
					@import "@/styles/globals.scss";
					

.base-select {
	$self: &;
	$select-height: 8.8rem;
	$select-height-small: 4.8rem;
	$select-border-radius: 0.8rem;

	@include heading-3;

	width: 100%;
	height: $select-height;
	position: relative;
	z-index: 1000;
	cursor: pointer;

	&__inner {
		@include dropshadow-input-small;
		display: flex;
		width: 100%;
		height: 100%;
		transition: box-shadow 250ms $easeOutQuad;
		border: none;
		border-radius: $select-border-radius;
		background-color: rgba($color-white, 0.9);
	}

	&__content {
		display: flex;
		align-items: center;
		width: 100%;
		min-width: 0;
	}

	&__placeholder {
		color: $color-gray;
	}

	&__selected-option {
		color: $color-black;
	}

	&__placeholder,
	&__selected-option {
		padding-left: 1.6rem;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	&__indicator {
		min-width: 4rem;
		margin: 0 1.6rem;
		position: relative;

		&::after {
			content: '';
			display: block;
			width: 1.3rem;
			height: 1.3rem;
			position: absolute;
			top: 50%;
			left: 50%;
			transition: transform 250ms $easeOutQuad;
			transform: translate(-75%, -75%) rotate(45deg);
			transform-origin: 75% 75%;
			border: 1px solid $color-dark;
			border-width: 0 1px 1px 0;
		}
	}

	&__dropdown {
		width: 100%;
		position: absolute;
		top: 100%;
		top: calc(100% - #{$select-border-radius});
		z-index: 1;
		word-break: break-all;
		visibility: hidden;
		list-style: none;
		transition: visibility 250ms $easeOutQuad, opacity 250ms $easeOutQuad;
		opacity: 0;
		border-bottom-right-radius: $select-border-radius;
		border-bottom-left-radius: $select-border-radius;
		background: rgba($color-white, 0.9);
		will-change: visibility;

		&::before {
			@include dropshadow-input-small;
			content: '';
			display: block;
			position: absolute;
			top: -$select-height + $select-border-radius;
			right: 0;
			bottom: 0;
			left: 0;
			border-radius: $select-border-radius;
		}
	}

	&__list {
		max-height: min(31rem, 40vh);
		position: relative;
		overflow: auto;
		list-style: none;
		cursor: default;
		outline: none;
	}

	&__list-item {
		display: flex;
		align-items: center;
		height: $select-height;
		padding: 0 1.6rem;
		color: $color-gray;

		.base-select-dropdown--is-small & {
			height: $select-height-small;
		}
	}

	&__option {
		cursor: pointer;
		transition: background-color 250ms $easeOutQuad;
		background-color: rgba($color-light, 0.36);

		&--is-first {
			border-top-left-radius: $select-border-radius;
			border-top-right-radius: $select-border-radius;
		}

		&--is-last {
			border-bottom-right-radius: $select-border-radius;
			border-bottom-left-radius: $select-border-radius;
		}

		&--is-disabled {
			pointer-events: none;
		}

		&--is-disabled,
		&--is-selected {
			color: rgba($color-gray, 0.3);
			cursor: default;
		}

		&:not(&--is-selected) {
			&:hover,
			&:focus {
				background-color: rgba($color-white, 0.9);
			}
		}
	}

	&__list-header {
		border-top-left-radius: $select-border-radius;
		border-top-right-radius: $select-border-radius;
	}

	&__list-footer {
		border-bottom-right-radius: $select-border-radius;
		border-bottom-left-radius: $select-border-radius;
	}

	&__list-header,
	&__list-footer {
		background-color: rgba($color-white, 0.9);
	}

	&__option-content {
		width: 100%;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	&__dropdown-background {
		display: none;
		position: absolute;
		z-index: -1;
	}

	&--is-open {
		#{$self} {
			&__dropdown {
				visibility: visible;
				opacity: 1;
			}

			&__inner {
				box-shadow: none;
			}

			&__content {
				opacity: 0.6;
			}

			&__indicator::after {
				transform: translate(-75%, -75%) rotate(45deg) rotate3d(-1, 1, 0, -180deg);
			}
		}
	}

	&--is-small {
		@include small-text;
		height: $select-height-small;
	}

	&-dropdown {
		@include heading-3;
		display: none;
		cursor: pointer;

		&--is-open {
			display: block;
			margin-top: -#{$select-border-radius};
			position: fixed;
			z-index: 999999;
			visibility: visible;
			opacity: 1;
		}

		&--bottom {
			border-top-left-radius: $select-border-radius;
			border-top-right-radius: $select-border-radius;
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;

			&::before {
				top: 0;
				bottom: calc(-8rem - #{$select-border-radius});
			}
		}

		&--is-small {
			@include small-text;
			background: $color-white;

			&::before {
				top: -4rem;
			}
		}
	}
}

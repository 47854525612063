
					@use 'sass:math';
					@import "@/styles/globals.scss";
					

.base-table {
	table {
		width: 100%;

		thead {
			tr {
				border-bottom: 1px solid #eaeaea;
			}

			th {
				padding: 1rem;
				color: #666;
				font-size: 1.2rem;
				font-weight: 500;
				text-align: left;
			}
		}

		tbody {
			tr {
				border-bottom: 1px solid #eaeaea;
			}

			td {
				padding: 1rem;
				font-size: 0.9em;
				vertical-align: middle;

				&.base-table__td--primary {
					font-size: 1em;
					font-weight: 500;
				}
			}

			&:last-child {
				tr {
					&:last-child {
						border-bottom: none;
					}
				}
			}
		}

		tfoot {
			td {
				padding: 2rem 0 1rem;
				text-align: left;
			}
		}

		td,
		th {
			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0;

				&:not(:first-child) {
					text-align: right;
				}
			}
		}
	}
}


					@use 'sass:math';
					@import "@/styles/globals.scss";
					

.app-cover {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 2rem;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 99999;
	color: $color-dark;
	font-size: 2.4rem;
	line-height: 1.24;
	pointer-events: none;
	background-color: $color-light;

	&__use-tablet {
		text-align: center;
	}
}

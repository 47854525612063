
					@use 'sass:math';
					@import "@/styles/globals.scss";
					

.form {
	width: 100%;

	&__header {
		padding: 7rem 0 1rem;

		@include min-md {
			padding: 5rem 0 3rem;
		}
	}

	&__content {
		padding: 1.5rem 0;
	}
}

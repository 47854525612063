.preloader {
	display: flex;
	width: 100%;
	height: 100%;
	min-height: 3.2rem;
	position: absolute;
	top: 0;
	left: 0;

	&__inner {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		max-height: vh(50);
		position: sticky;
		top: vh(10);
	}

	&__spinner {
		$_spinner_appear_anim: animation-name-auto('spinner-appear');

		animation: #{$_spinner_appear_anim} 300ms forwards $easeOutQuad;

		@keyframes #{$_spinner_appear_anim} {
			from {
				transform: translateY(2rem);
				opacity: 0;
			}

			to {
				transform: none;
				opacity: 1;
			}
		}
	}
}

.preloader-spinner {
	@include dropshadow-default;
	width: 6rem;
	height: 3.2rem;
	position: relative;
	border-radius: 0.8rem;
	background: $color-white;

	&__helper {
		$_dot_size: 0.7rem;
		$_dot_anim: animation-name-auto('dot');
		$_dot_color: $color-gray;
		$_dot_min_alpha: 0.4;

		margin-top: (-0.5 * $_dot_size);
		margin-left: (-0.5 * $_dot_size);
		top: 50%;
		left: 50%;

		&,
		&::before,
		&::after {
			width: $_dot_size;
			height: $_dot_size;
			position: absolute;
			animation: #{$_dot_anim} 1s infinite linear;
			border-radius: 50%;
			background: $color-medium;
		}

		&::before,
		&::after {
			content: '';
			display: block;
		}

		& {
			animation-delay: 100ms;
		}

		&::before {
			right: $_dot_size * 2;
			animation-delay: 0ms;
		}

		&::after {
			left: $_dot_size * 2;
			animation-delay: 200ms;
		}

		@keyframes #{$_dot_anim} {
			0% {
				background: rgba($_dot_color, $_dot_min_alpha);
			}

			20% {
				background: rgba($_dot_color, 1);
			}

			40% {
				background: rgba($_dot_color, $_dot_min_alpha);
			}

			100% {
				background: rgba($_dot_color, $_dot_min_alpha);
			}
		}
	}
}

.has-preloader {
	@include loading-state;
	position: relative;

	.preloader {
		opacity: 1;
	}
}


					@use 'sass:math';
					@import "@/styles/globals.scss";
					

.page-two-columns {
	$self: &;

	display: grid;
	grid-column-gap: 2.4rem;
	grid-row-gap: 0;
	grid-template-columns: repeat(24, 1fr);
	grid-template-rows: 1fr;
	min-height: 100%;

	@include max-md-and-portrait {
		grid-template-rows: auto 1fr;
	}

	&__column {
		.page-two-columns-column__content {
			padding: 6.5rem 0 12rem;

			@include max-lg-and-portrait {
				padding: 6.2rem 0 4.8rem;
			}
		}

		.page-two-columns-column__sticky-container {
			flex: 1;
		}
	}

	&__column--left {
		grid-column: 1 / span 12;

		@include max-md-and-portrait {
			grid-column: 1 / span 24;
			min-height: 0;
		}
	}

	&__column--right {
		grid-column: 1 / span 12;

		@include max-md-and-portrait {
			grid-column: 1 / span 24;
		}
	}

	&--one-third {
		#{$self}__column--left {
			grid-column: 1 / span 8;

			@include max-lg-and-portrait {
				grid-column: 1 / span 6;
			}

			@include max-md-and-portrait {
				grid-column: 1 / span 24;
			}
		}

		#{$self}__column--right {
			grid-column: 9 / span 16;

			@include max-lg-and-portrait {
				grid-column: 7 / span 18;
			}

			@include max-md-and-portrait {
				grid-column: 1 / span 24;
			}
		}
	}

	&--one-fourth {
		#{$self}__column--left {
			grid-column: 1 / span 6;

			@include max-md-and-portrait {
				grid-column: 1 / span 24;
			}
		}

		#{$self}__column--right {
			grid-column: 7 / span 18;

			@include max-md-and-portrait {
				grid-column: 1 / span 24;
			}
		}
	}

	&--five-to-seven {
		#{$self}__column--left {
			grid-column: 1 / span 10;

			@include max-md-and-portrait {
				grid-column: 1 / span 24;
			}
		}

		#{$self}__column--right {
			grid-column: 11 / span 14;

			@include max-md-and-portrait {
				grid-column: 1 / span 24;
			}
		}
	}

	&--has-tablet-layout {
		&#{$self}--one-third {
			#{$self}__column--left {
				@include max-xl {
					grid-column: 1 / span 24;
				}
			}

			#{$self}__column--right {
				@include max-xl {
					grid-column: 1 / span 24;
				}
			}
		}

		&#{$self}--one-fourth {
			#{$self}__column--left {
				@include max-xl {
					grid-column: 1 / span 24;
				}
			}

			#{$self}__column--right {
				@include max-xl {
					grid-column: 1 / span 24;
				}
			}
		}

		&#{$self}--five-to-seven {
			#{$self}__column--left {
				@include max-xl {
					grid-column: 1 / span 24;
				}
			}

			#{$self}__column--right {
				@include max-xl {
					grid-column: 1 / span 24;
				}
			}
		}
	}
}

.page-two-columns-column {
	&__content {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		height: 100%;
	}

	&.page-two-columns__column--right {
		.page-two-columns-column__content {
			@include max-md-and-portrait {
				padding-top: 3.2rem;
			}
		}
	}

	&__after {
		margin-top: auto;
	}

	&__sticky--native {
		position: sticky;
		top: 14rem;
	}
}

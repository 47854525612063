
					@use 'sass:math';
					@import "@/styles/globals.scss";
					

.layout-system {
	&,
	&__layout,
	&__view-wrapper,
	&__view {
		width: 100%;
	}

	&__view {
		display: flex;
		min-height: 100%;

		& > * {
			width: 100%;
		}
	}
}

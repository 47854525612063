
					@use 'sass:math';
					@import "@/styles/globals.scss";
					

.app-dialog {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 8rem;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10000;
	color: $color-light;

	&__backdrop {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		background: rgba($color-gray, 0.4);
	}

	&__content {
		@include dropshadow-selected();
		display: flex;
		width: 43rem;
		padding: 2.4rem 3.2rem 3.2rem;
		position: relative;
		z-index: 10;
		border-radius: 1.6rem;
		background: $color-white;
	}
}


					@use 'sass:math';
					@import "@/styles/globals.scss";
					

.order-rejected-dialog {
	color: $color-gray;

	&__heading {
		@include heading-2;
		margin-bottom: 2.8rem;
		word-break: break-word;
	}

	&__description {
		a {
			color: inherit;
			text-decoration: none;
			transition-property: color, border;
			opacity: 1;
			border-bottom: 1px solid transparent;

			&:hover {
				color: $color-primary;
				border-bottom-color: currentColor;
			}
		}
	}

	&__buttons {
		display: flex;
		justify-content: center;
		margin-top: 5.2rem;

		.base-button {
			min-width: 14rem;
			min-height: 5.6rem;
		}
	}
}


					@use 'sass:math';
					@import "@/styles/globals.scss";
					

.app-card {
	@include heading-3;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	width: 100%;
	min-height: 32rem;
	padding: 1.8rem 2.1rem 2.4rem;
	border-radius: 0.8rem;
	border-radius: 0.8rem;
	background: $color-white;

	&__header {
		display: flex;
		width: 100%;
		padding-bottom: 1.5rem;
	}

	&__footer {
		display: flex;
		width: 100%;
		margin-top: auto;
		padding-top: 1.5rem;
	}
}

body {
	font-size: $font-size-body;
}

.heading-1 {
	@include heading-1;
}

.heading-2 {
	@include heading-2;
}

.heading-3 {
	@include heading-3;
}

sup {
	display: inline-block;
	margin: 0 0.5em;
	top: -1em;
	font-size: 0.6429em;
}


					@use 'sass:math';
					@import "@/styles/globals.scss";
					

:root {
	--vh: 1vh;
}

.app {
	display: flex;
	align-items: stretch;
	min-height: $view-height;
	line-height: 1.5;
}

.terms-and-conditions-dialog {
	.app-dialog__content {
		width: 80vw;
		max-width: 1200px;
	}
}


					@use 'sass:math';
					@import "@/styles/globals.scss";
					

.base-button {
	$self: &;

	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 14.7rem;
	padding: 1.6rem 3.2rem;
	position: relative;
	color: $color-dark;
	font-size: 1.6rem;
	text-decoration: none;
	cursor: pointer;
	transition: all 300ms ease, transform 100ms ease-out;
	border: 1px solid $color-dark;
	border-radius: 1.2rem;
	background: transparent;
	appearance: none;

	@include max-lg-and-portrait {
		font-size: 1.5rem;
	}

	&:hover,
	&:active {
		color: $color-light;
		border: 1px solid $color-dark;
		border-radius: 1.6rem;
		background: $color-dark;
		box-shadow: 0 5px 20px rgba(32, 30, 30, 0.1);
	}

	&:focus {
		box-shadow: 0 5px 20px rgba(32, 30, 30, 0.1);
	}

	&:active {
		transform: scale(0.9);
	}

	.is-safari &:is(a) {
		-webkit-user-select: none;
	}

	&__icon {
		position: relative;
		z-index: 10;

		svg {
			display: block;
			width: auto;
			overflow: visible;

			&:not([fill='none']) {
				path:not(.icon-exception) {
					fill: currentColor;
				}
			}

			&[fill='none'] {
				path:not(.icon-exception) {
					stroke: currentColor;
				}
			}
		}
	}

	&__text {
		position: relative;
		z-index: 5;

		@include max-lg-and-portrait {
			font-size: 1.5rem;
		}
	}

	&__icon + &__text {
		margin-left: 0.5em;
	}

	&--small {
		height: 4.8rem;
		padding-top: 1rem;
		padding-bottom: 1rem;
		font-size: 1.6rem;
		letter-spacing: 0.021em;

		@include max-lg-and-portrait {
			font-size: 1.5rem;
		}
	}

	&--large {
		@include heading-3;
		padding: 1.6rem 2rem;
	}

	&--primary {
		color: $color-light;
		border-color: $color-dark;
		background: $color-dark;

		&:hover,
		&:active {
			color: $color-dark;
			border: 1px solid $color-dark;
			background: $color-light;
		}
	}

	&--secondary {
		color: $color-light;
		border-color: $color-light;

		&:hover,
		&:active {
			color: $color-dark;
			border: 1px solid $color-light;
			background: $color-light;
		}
	}

	&--minimal {
		padding: 1.6rem 1rem;
		color: $color-dark;
		font-size: 1.8rem;
		text-decoration: underline;
		border: none;

		@include max-lg-and-portrait {
			font-size: 1.5rem;
		}

		&:hover,
		&:focus,
		&:active {
			color: $color-dark;
			border: none;
			background-color: transparent;
			box-shadow: none;
		}
	}

	&--inline {
		display: inline-flex;
		margin: 0;
		padding: 0;
		color: inherit;
		font-size: inherit;
		text-decoration: none;
		border: none;

		&:hover,
		&:focus,
		&:active {
			color: $color-dark;
			text-decoration: underline;
			border: none;
			background-color: transparent;
			box-shadow: none;
		}
	}

	&--download {
		@include small-text;
		padding: 1.4rem 1.6rem;

		#{$self}__icon > svg {
			width: 1.8rem;
			height: 2rem;
		}
	}

	&--reversed {
		padding: 1.6rem;

		&#{$self}--small {
			padding-top: 1rem;
			padding-bottom: 1rem;
		}
	}

	&--download,
	&--reversed {
		flex-direction: row-reverse;
		justify-content: space-between;

		#{$self}__text {
			margin-right: 0.5em;
			margin-left: 0;
		}
	}

	&--has-icon-border {
		justify-content: flex-start;
		padding: 0.8rem;

		.base-button__icon {
			padding: 0.8rem;
			transition: border 300ms ease;
			border: 1px solid currentColor;
			border-radius: 0.8rem;
		}

		.base-button__text {
			margin: auto;
			position: relative;
		}

		&:hover,
		&:active {
			.base-button__icon {
				border: 1px solid currentColor;
			}
		}
	}

	&--icon-only {
		width: 4rem;
		min-width: 0;
		height: 4rem;
		padding: 1.1rem;

		.base-button__text {
			display: none;
		}
	}

	&--is-loading {
		pointer-events: none;
		opacity: 0.7;

		&::before {
			$_size: 1.4em;
			$_loading_anim: animation-name-auto('loading');

			content: '';
			display: block;
			width: $_size;
			height: $_size;
			margin: math.div($_size, -2) 0 0 math.div($_size, -2);
			position: absolute;
			top: 50%;
			left: 50%;
			animation: #{$_loading_anim} 500ms linear infinite;
			border: 2px solid;
			border-color: currentColor currentColor transparent transparent;
			border-radius: 50%;

			@keyframes #{$_loading_anim} {
				0% {
					transform: rotate(0deg);
				}

				100% {
					transform: rotate(360deg);
				}
			}
		}

		.base-button__text,
		.base-button__icon {
			opacity: 0;
		}
	}

	&[disabled],
	&--is-disabled {
		color: $color-gray;
		pointer-events: none;
		border: 1px solid $color-gray;
	}
}


					@use 'sass:math';
					@import "@/styles/globals.scss";
					

.base-image {
	width: 100%;
	height: 100%;
	object-fit: contain;
	transition: opacity 0.2s ease;

	&--cover {
		object-fit: cover;
	}

	&--hover {
		opacity: 0;
	}
}

.base-image-multiple {
	width: 100%;
	height: 100%;

	&:hover {
		.base-image {
			opacity: 0;

			&--hover {
				opacity: 1;
			}
		}
	}
}

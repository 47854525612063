
					@use 'sass:math';
					@import "@/styles/globals.scss";
					

.error-message {
	display: block;
	color: $color-error;
	font-size: 1.6rem;
	font-weight: 500;
	line-height: 1.1875;
	animation: animation-name-auto('in') 300ms forwards $easeOutQuad;
	opacity: 0;

	@keyframes #{animation-name-auto('in')} {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}
}

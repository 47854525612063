
					@use 'sass:math';
					@import "@/styles/globals.scss";
					

.base-dropdown {
	$self: &;
	$width: 19rem;

	min-width: $width;
	position: relative;

	&__control {
		display: flex;
		justify-content: flex-end;
		min-width: $width;
	}

	&__backdrop {
		$backdrop-offset: 0.4rem;

		display: none;
		position: absolute;
		top: 0;

		&::before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			right: -1 * $backdrop-offset;
			bottom: -1 * $backdrop-offset;
			left: -1 * $backdrop-offset;
			border-radius: 1.2rem;
			background-color: $color-white;
			box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
		}
	}

	&__dropdown {
		display: none;
		min-width: $width;
		padding: 1.2rem 1.5rem;
		position: absolute;
		top: 100%;
		box-sizing: border-box;
		border-radius: 0.8rem;
		background: #fff;
	}

	&--align-left {
		#{$self}__dropdown,
		#{$self}__backdrop {
			left: 0;
		}
	}

	&--align-right {
		#{$self}__dropdown,
		#{$self}__backdrop {
			right: 0;
		}
	}

	&--align-center {
		#{$self}__dropdown,
		#{$self}__backdrop {
			margin-left: math.div($width, -2);
			left: 50%;
		}
	}

	&--is-open {
		#{$self}__dropdown {
			display: block;
			transform-origin: 50% 0;
			animation: animation-name-auto('in') 200ms 50ms forwards $easeOutQuad;
			opacity: 0;
			box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);

			@keyframes #{animation-name-auto('in')} {
				0% {
					transform: translateY(-0.5rem);
					animation-timing-function: $linear;
					opacity: 0;
				}

				30% {
					transform: translateY(-0.4rem);
					opacity: 1;
				}

				to {
					transform: none;
					opacity: 1;
				}
			}
		}

		#{$self}__backdrop {
			display: block;
			transform-origin: 100% 0;
			animation: animation-name-auto('in') 250ms $easeOutQuad;

			@keyframes #{animation-name-auto('in')} {
				0% {
					transform: translate(-0.5rem, 0.5rem) scale(0.85, 0.9);
					animation-timing-function: $linear;
				}

				30% {
					transform: scale(0.95, 0.95);
					animation-timing-function: $easeOutQuad;
				}

				to {
					transform: none;
				}
			}
		}

		#{$self}__control {
			button {
				border: 1px solid transparent;
			}
		}
	}
}


					@use 'sass:math';
					@import "@/styles/globals.scss";
					

.base-pagination {
	display: inline-flex;
	align-items: center;
	border: 1px solid #eaeaea;
	border-radius: 0.4rem;

	&__item {
		&--current {
			text-decoration: underline;
			pointer-events: none;
			background: #f0f0f0;
		}

		&--prev {
			border-right: 1px solid #eaeaea;

			.base-button {
				padding-right: 1.2rem;
				padding-left: 1.2rem;
			}
		}

		&--next {
			border-left: 1px solid #eaeaea;

			.base-button {
				padding-right: 1.2rem;
				padding-left: 1.2rem;
			}
		}

		&--ellipsis {
			padding: 0 1rem;
			color: #999;
		}

		.base-button {
			border-radius: 0;

			&:disabled {
				pointer-events: none;
				opacity: 0.5;
			}
		}

		&:first-child {
			.base-button {
				border-radius: 0.4rem 0 0 0.4rem;
			}
		}

		&:last-child {
			.base-button {
				border-radius: 0 0.4rem 0.4rem 0;
			}
		}
	}
}

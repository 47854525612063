
					@use 'sass:math';
					@import "@/styles/globals.scss";
					

.layout-order {
	&__title {
		grid-column: 3 / span 5;
		align-self: center;
		font-size: 1.5rem;

		@include max-xl {
			grid-column: 3 / span 4;
		}

		@include max-lg-and-portrait {
			margin-left: -1.8rem;
		}

		@include max-md-and-portrait {
			display: none;
		}
	}

	&__nav {
		grid-column: 9 / span 14;
		align-self: center;

		@include max-xl {
			grid-column: 7 / span 16;
		}

		@include max-md-and-portrait {
			grid-column: 3 / span 20;
		}
	}
}

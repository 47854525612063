
					@use 'sass:math';
					@import "@/styles/globals.scss";
					

.app-confirm-dialog {
	&__inner-content {
		width: 100%;
	}

	&__message {
		@include heading-2;
		margin-bottom: 8rem;
		color: $color-gray;
		word-break: break-word;

		@include max-lg-and-portrait {
			font-size: 3.2rem;
		}
	}

	&__buttons {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}

	&__button {
		width: 14rem;
		padding-right: 1.6rem;
		padding-left: 1.6rem;
	}
}


					@use 'sass:math';
					@import "@/styles/globals.scss";
					

.user-menu {
	$self: &;

	margin: -0.7rem 0 -1.5rem;
	padding: 0.7rem 0 1.5rem;

	@mixin is-dropdown-open {
		#{$self}.base-dropdown--is-open & {
			@content;
		}
	}

	&.base-dropdown--is-open {
		z-index: 1000;
	}

	&.base-dropdown,
	.base-dropdown__control {
		min-width: auto;
	}

	&--with-username {
		&.base-dropdown,
		.base-dropdown__control {
			min-width: 17.4rem;
		}
	}

	&__group {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		@include is-dropdown-open {
			justify-content: flex-end;
		}
	}

	&__welcome {
		padding-right: 0.4rem;
		font-size: $font-size-small;
		transition: all 250ms $easeOutQuad;
		transition-property: transform;
		will-change: transform;
		backface-visibility: hidden;

		@include is-dropdown-open {
			width: 12.9rem;
			position: absolute;
			right: 4.5rem;
			transform: scale(1.1) translate(0.5rem, 0);

			@include max-lg-and-portrait {
				visibility: visible;
				opacity: 1;
			}
		}

		@include max-lg-and-portrait {
			font-size: 1.5rem;
			visibility: hidden;
			transition-property: transform, opacity;
			opacity: 0;
		}
	}

	&__activator {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 4.8rem;
		height: 4.8rem;
		position: relative;
		cursor: pointer;
		transition: all 0.2s ease-in-out;
		border: 1px solid $color-black;
		border-radius: 0.8rem;

		@include max-lg-and-portrait {
			width: 4.4rem;
			height: 4.4rem;
		}

		&:hover {
			#{$self}__icon {
				&::before {
					transform: translateY(-2px);
				}

				&::after {
					transform: translateY(2px);
				}
			}
		}
	}

	&__icon {
		width: 1.8rem;
		height: 1px;
		margin: 1.2rem;
		position: relative;
		transition: transform 0.2s;
		background-color: black;

		&::before,
		&::after {
			content: '';
			width: 1.8rem;
			height: 100%;
			position: absolute;
			left: 0;
			transition: all 0.2s;
			background-color: black;
		}

		&::before {
			top: -5px;
		}

		&::after {
			top: 5px;
		}

		&--close {
			width: 0;
			left: -20%;

			&::before,
			&::after {
				top: 0;
			}

			&::before {
				transform: rotate(45deg) translateY(50%) scaleX(0);
				animation: animation-name-auto('open-before') 250ms $easeOutQuad forwards;

				@keyframes #{animation-name-auto('open-before')} {
					from {
						transform: rotate(45deg) translateY(50%) scaleX(0);
					}

					to {
						transform: rotate(45deg) translateY(50%) scaleX(1);
					}
				}
			}

			&::after {
				transform: rotate(-45deg) scaleX(0);
				animation: animation-name-auto('open-after') 250ms 100ms $easeOutQuad forwards;

				@keyframes #{animation-name-auto('open-after')} {
					from {
						transform: rotate(-45deg) scaleX(0);
					}

					to {
						transform: rotate(-45deg) scaleX(1);
					}
				}
			}
		}
	}

	&__menu {
		.current-user {
			font-weight: 500;
		}

		.base-menu-item {
			margin-right: -1.5rem;
			margin-left: -1.5rem;
			font-size: $font-size-body;

			@include max-lg-and-portrait {
				font-size: 1.65rem;
			}

			a {
				text-decoration: none;
			}

			&__activator {
				position: relative;
				overflow: hidden;

				&--is-clickable {
					.base-menu-item__label {
						position: relative;
						z-index: 10;
					}

					&::before {
						content: '';
						display: block;
						width: 100%;
						height: 100%;
						position: absolute;
						top: 0;
						left: 0;
						transition: opacity 150ms ease-out;
						transform-origin: 50% 100%;
						opacity: 0;
						background: $color-light;
					}

					&:hover {
						.base-menu-item__label {
							@keyframes #{animation-name-auto('hover')} {
								100% {
									transform: translateY(0%);
									opacity: 1;
								}

								50% {
									transform: translateY(50%);
									opacity: 0;
								}

								51% {
									transform: translateY(-50%);
									opacity: 0;
								}

								100% {
									transform: translateY(0%);
									opacity: 1;
								}
							}
						}

						&::before {
							animation: animation-name-auto('hover') 200ms $easeOutQuad;
							opacity: 1;

							@keyframes #{animation-name-auto('hover')} {
								from {
									transform: scaleY(0.5) translateY(-100%);
									border-radius: 0 0 50% 50%;
								}

								to {
									transform: scaleY(1);
									border-radius: 0;
								}
							}
						}
					}
				}
			}
		}

		.base-menu-item__activator {
			padding-right: 1.5rem;
			padding-left: 1.5rem;
		}
	}

	&__logout {
		cursor: pointer;
		opacity: 0.8;
	}
}


					@use 'sass:math';
					@import "@/styles/globals.scss";
					

.form-element {
	$self: &;

	&__buttons {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 8rem;

		.base-button {
			padding: 1.5rem 3.8rem;

			&.base-button--minimal {
				min-width: initial;
				padding: 1.5rem 0;
			}
		}

		&--has-offset {
			display: grid;
			grid-template-columns: 3fr 4fr;
			gap: 2.4rem 2.4rem;
		}
	}

	&__buttons-offset-wrapper {
		display: flex;
		grid-column-start: 2;
		align-items: center;
		justify-content: space-between;
	}

	&--is-loading {
		#{$self}__content {
			@include loading-state;
		}
	}
}


					@use 'sass:math';
					@import "@/styles/globals.scss";
					

.sidebar-dashboard {
	$height: calc(#{vh(100)} - #{$header-height});

	width: 100%;
	height: $height;
	position: relative;

	@include max-md-and-portrait {
		height: auto;
	}

	&__container {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: $height;
		padding-top: 12.8rem;
		padding-bottom: 11.8rem;
		position: fixed;
		overflow-y: scroll;
		overscroll-behavior-y: contain;

		@include max-md-and-portrait {
			padding-top: 8rem;
			padding-bottom: 0;
			position: static;
		}

		@include max-tablet-portrait {
			height: auto;
		}

		&::-webkit-scrollbar {
			display: none;
		}
	}

	&__header {
		padding-right: 4.8rem;

		@include max-xl {
			width: 23rem;
		}

		@include max-lg-and-portrait {
			padding-right: 0;
		}
	}

	&__heading {
		@include heading-2;
		margin-bottom: 2.4rem;
	}

	&__description {
		@include small-text;
	}

	&__buttons {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		width: auto;
		margin-bottom: 0;

		@include max-lg-and-portrait {
			width: 23rem;
			margin-top: 5rem;
		}

		@include max-md-and-portrait {
			display: grid;
			grid-column-gap: 1.6rem;
			grid-template-columns: 1fr 1fr;
			min-width: 100%;
			margin-top: 7rem;
		}
	}

	&__button {
		@include heading-3;
		width: 100%;
		margin: 1.2rem 0;

		@include max-lg-and-portrait {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}

		@include min-xl {
			width: 26rem;
			height: 8rem;
		}

		&.base-button {
			justify-content: flex-start;
			padding: 1.6rem 2rem;

			@include max-xl {
				font-size: 2.4rem;
			}

			@include max-lg-and-portrait {
				padding: 2rem;
				font-size: 2.4rem;
			}
		}

		.base-button__icon + .base-button__text {
			margin-left: 2.6rem;
			line-height: 1.24;

			@include max-lg-and-portrait {
				margin-left: 2.7rem;
				font-size: 2.4rem;
			}
		}
	}
}

@use 'sass:math';

@mixin min-sm {
	@media all and (min-width: map-get($breakpoints, sm)) {
		@content;
	}
}

@mixin min-md {
	@media all and (min-width: map-get($breakpoints, md)) {
		@content;
	}
}

@mixin min-lg {
	@media all and (min-width: map-get($breakpoints, lg)) {
		@content;
	}
}

@mixin min-xl {
	@media all and (min-width: map-get($breakpoints, xl)) {
		@content;
	}
}

@mixin min-shd {
	@media all and (min-width: map-get($breakpoints, shd)) {
		@content;
	}
}

@mixin min-hd {
	@media all and (min-width: map-get($breakpoints, hd)) {
		@content;
	}
}

@mixin max-sm {
	@media all and (max-width: map-get($breakpoints, sm)) {
		@content;
	}
}

@mixin max-md {
	@media all and (max-width: map-get($breakpoints, md)) {
		@content;
	}
}

@mixin max-md-and-portrait {
	@media all and (max-width: map-get($breakpoints, md)), all and (max-width: map-get($breakpoints, xl)) and (orientation: portrait) {
		@content;
	}
}

@mixin max-lg {
	@media all and (max-width: map-get($breakpoints, lg)) {
		@content;
	}
}

@mixin max-lg-and-portrait {
	@media all and (max-width: map-get($breakpoints, lg)), all and (max-width: map-get($breakpoints, xl)) and (orientation: portrait) {
		@content;
	}
}

@mixin max-tablet-portrait {
	@media all and (max-width: map-get($breakpoints, xl)) and (orientation: portrait) {
		@content;
	}
}

@mixin max-xl {
	@media all and (max-width: map-get($breakpoints, xl)) {
		@content;
	}
}

@mixin max-shd {
	@media all and (max-width: map-get($breakpoints, shd)) {
		@content;
	}
}

@mixin max-hd {
	@media all and (max-width: map-get($breakpoints, hd)) {
		@content;
	}
}

@mixin landscape {
	@media all and (orientation: landscape) {
		@content;
	}
}

@mixin portrait {
	@media all and (orientation: portrait) {
		@content;
	}
}

@mixin dropshadow-selected {
	box-shadow: 0 0.5rem 2rem rgba($color-dark, 0.1);
}

@mixin dropshadow-default {
	box-shadow: 0 0.2rem 0.8rem rgba($color-dark, 0.1);
}

@mixin dropshadow-input-small {
	@include dropshadow-default();
}

@mixin dropshadow-input-focus {
	@include dropshadow-selected();
}

@mixin heading-1 {
	font-size: $font-size-h1;
	font-weight: 500;
	line-height: 1.2;
	letter-spacing: 0.01em;

	@include max-lg-and-portrait {
		font-size: 3.2rem;
		line-height: 1.2;
	}
}

@mixin heading-2 {
	font-size: $font-size-h2;
	font-weight: 500;
	line-height: 4.8rem;
	letter-spacing: 0.04em;

	@include max-lg-and-portrait {
		font-size: 2.8rem;
		line-height: 1.2;
	}
}

@mixin heading-3 {
	font-size: $font-size-h3;
	font-weight: 500;
	line-height: 3.5rem;
	letter-spacing: 0.03em;

	@include max-lg-and-portrait {
		font-size: 2.4rem;
		line-height: 1.24;
	}
}

@mixin heading-4 {
	font-size: $font-size-h4;
}

@mixin body-text {
	font-size: $font-size-body;
	font-weight: inherit;
	line-height: inherit;
	line-height: 1.26;
	letter-spacing: 0.03em;

	@include max-lg-and-portrait {
		font-size: 1.5rem;
	}
}

@mixin body-text-bold {
	font-size: $font-size-body;
	font-weight: 500;
	line-height: 1.26;
	letter-spacing: 0.03em;

	@include max-lg-and-portrait {
		font-size: 1.5rem;
	}
}

@mixin small-text {
	font-size: $font-size-small;
	font-weight: inherit;
	line-height: math.div(19, 16);
	letter-spacing: 0.02em;

	@include max-lg-and-portrait {
		font-size: 1.5rem;
	}
}

@mixin label-text {
	font-size: $font-size-label;
	font-weight: inherit;
	line-height: math.div(14, 12);
	letter-spacing: 0.04em;
}

@mixin loading-state($opacity: 0.5) {
	cursor: wait;

	& > * {
		pointer-events: none;
		opacity: $opacity;
	}
}

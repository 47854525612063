
					@use 'sass:math';
					@import "@/styles/globals.scss";
					

.password-forgot-form {
	color: $color-light;

	&__title {
		@include heading-1;
	}

	&__description {
		margin: 2.4rem 0 0;
		font-size: 1.8rem;
	}

	.form-item {
		label {
			color: inherit;
		}

		input {
			&,
			&:focus,
			&:-webkit-autofill,
			&:-webkit-autofill:active,
			&:-webkit-autofill:hover,
			&:-webkit-autofill:focus {
				color: $color-dark !important;
				font-size: $font-size-input;
				background-color: $color-light !important;
			}
		}
	}
}


					@use 'sass:math';
					@import "@/styles/globals.scss";
					

.landing-slider {
	--active-color: #{$color-white};

	$self: &;

	.slider,
	.swiper,
	.swiper-slide {
		height: 100%;
	}

	.swiper {
		.swiper-pagination {
			&--align-bottom-center {
				bottom: 3.2rem;

				@include max-lg-and-portrait {
					bottom: 3rem;
				}
			}
		}

		.swiper-pagination-bullet {
			border: 1px solid var(--active-color);

			&-active {
				background-color: var(--active-color);
			}
		}
	}

	&__slider {
		transition: background-color $landing-bg-transition;
		border-radius: 1.6rem 0 0 1.6rem;

		@include max-md-and-portrait {
			border-radius: 1.6rem 1.6rem 0 0;
		}
	}

	&__slide {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		width: 100%;
		height: 100%;
		position: relative;
		box-sizing: border-box;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}

	&__image {
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: 0;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}

	&__title {
		@include heading-3;
		margin-bottom: 4.4rem;
		padding-bottom: 3.2rem;
		z-index: 1;
		color: var(--active-color);
		text-align: center;
	}
}

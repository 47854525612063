
					@use 'sass:math';
					@import "@/styles/globals.scss";
					

.layout-landing {
	display: grid;
	/* prettier-ignore */
	grid-template-areas: 'content slider' 'terms slider';
	grid-template-columns: repeat(2, minmax(0, 1fr));
	grid-template-rows: 1fr auto;
	width: 100%;
	height: $view-height;
	transition: background-color $landing-bg-transition;
	gap: 0 0;

	@include max-md-and-portrait {
		display: flex;
		flex-direction: column;
	}

	&__header {
		.app-header__logo {
			margin: 0;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		grid-area: content;
		justify-content: center;
		width: 47rem;
		height: 100%;
		margin: 0 auto;
		padding: 0 0 0 4rem;

		@include max-xl {
			width: 40rem;
			padding: 1.5rem 0 0 5.8rem;
		}

		@include max-md-and-portrait {
			width: 100%;
			height: 50%;
			padding: 10.8rem 3.2rem 0;
		}
	}

	&__slider {
		display: block;
		grid-area: slider;

		@include max-md-and-portrait {
			height: 50%;
			margin-top: 5.4rem;
		}
	}

	&__terms {
		grid-area: terms;
		width: 43rem;
		margin: 0 auto;
		padding: 0 0 2rem;
		color: $color-light;
		font-size: 1.2rem;

		@include max-lg-and-portrait {
			width: 40rem;
			padding: 0 0 3.2rem 1.7rem;
		}
	}

	.layout-system__view {
		height: auto;
	}
}


					@use 'sass:math';
					@import "@/styles/globals.scss";
					

.view-not-found {
	.page-header__heading {
		padding-left: 1.2rem;
	}

	.page-two-columns-column__content {
		padding: 7.5rem 0 12rem;
	}

	&__text {
		@include heading-2;
		padding-top: 0.5rem;
		letter-spacing: 0.035em;
	}

	&__back-button.base-button {
		width: 14.8rem;
		height: 4.8rem;
		margin-left: auto;
		padding-right: 1rem;
		padding-left: 1rem;
		color: $color-primary;
	}
}


					@use 'sass:math';
					@import "@/styles/globals.scss";
					

.base-checkbox {
	$size: 4.8rem;

	display: flex;
	align-items: center;

	&__input {
		display: none;
	}

	&__check {
		width: $size;
		height: $size;
		position: relative;
		border: 1px solid $color-dark;
		border-radius: 0.8rem;
	}

	&__label {
		margin-left: 1.6rem;
		font-size: $font-size-small;
	}

	&__input:checked ~ &__check {
		&::before {
			content: '';
			display: block;
			width: 1.2rem;
			height: 2.8rem;
			position: absolute;
			top: 0.5rem;
			left: 1.75rem;
			transform: rotate(45deg);
			border-right: 1px solid black;
			border-bottom: 1px solid black;
		}
	}
}


					@use 'sass:math';
					@import "@/styles/globals.scss";
					

.layout-admin {
	&__nav {
		grid-column: 7 / span 12;
		align-self: center;

		@include max-lg-and-portrait {
			grid-column: 6 / span 16;
			margin-right: 0;
			margin-left: -3rem;
		}
	}
}

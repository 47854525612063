
					@use 'sass:math';
					@import "@/styles/globals.scss";
					

.fade {
	&-enter-active,
	&-leave-active {
		transition: opacity 0.25s $easeOutQuart;
	}

	&-enter-from,
	&-leave-to {
		opacity: 0;
	}
}

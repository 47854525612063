
					@use 'sass:math';
					@import "@/styles/globals.scss";
					

.login-form-contact {
	margin-top: 2rem;

	&__email {
		margin: 0;
		padding: 0;
		color: $color-light !important;
	}
}

.login-form {
	color: $color-light;

	&__title {
		margin-bottom: 0.5rem;
		font-size: $font-size-body;
	}

	&__description {
		font-size: 4.8rem;
		font-weight: 500;
		line-height: 1.22;
		letter-spacing: 0.025em;

		@include max-lg-and-portrait {
			font-size: 3.2rem;
		}
	}

	.form-item {
		margin-bottom: 4rem;

		@include max-lg-and-portrait {
			margin-bottom: 3.5rem;
		}

		label {
			color: inherit;
		}

		input {
			&,
			&:focus,
			&:-webkit-autofill,
			&:-webkit-autofill:active,
			&:-webkit-autofill:hover,
			&:-webkit-autofill:focus {
				color: $color-dark !important;
				font-size: $font-size-input;
				background-color: $color-light !important;
			}
		}

		&__error {
			margin-top: 1rem;
		}
	}

	.form-element__buttons {
		margin-top: 4rem;

		@include max-lg-and-portrait {
			margin-top: 4.5rem;
		}
	}
}

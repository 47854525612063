html {
	height: 100%;
	min-height: 100%;
	color: $color-dark;
	font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif,
		'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
	font-size: 62.5%;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	@media screen and (max-width: 1023px) {
		font-size: 62.5%;
	}
}

body {
	min-height: 100%;
	margin: 0;
	padding: 0;
	font-size: 1.8rem;

	@media screen and (max-width: 1024px) {
		font-size: 1.5rem;
	}
}

a {
	color: inherit;
}

button {
	display: inline;
	padding: 0;
	font: inherit;
	border: 0;
	outline: none;
	background: transparent;
	appearance: none;
}

svg {
	outline: none;
}

input,
textarea {
	border: none;

	&:focus {
		outline: 0;
	}
}

input {
	@include heading-3;
	@include dropshadow-input-small;
	height: 8.8rem;
	margin: 1.2rem 0 0;
	padding: 2.4rem 1.6rem;
	color: $color-black;
	transition: all 250ms $easeOutQuad;
	transition-property: background-color, box-shadow;
	border-radius: 0.8rem;
	background-color: rgba($color-white, 0.9);

	&:focus {
		&:not(:read-only):not(:disabled) {
			@include dropshadow-input-focus;
		}
	}

	&:read-only {
		cursor: not-allowed;
	}
}

textarea {
	@include dropshadow-input-small;
	width: 100%;
	min-height: 12rem;
	margin: 1.2rem 0 0;
	padding: 1.6rem;
	color: $color-black;
	resize: none;
	transition: all 250ms $easeOutQuad;
	transition-property: background-color, box-shadow;
	border-radius: 0.8rem;
	background-color: rgba($color-white, 0.9);
}

* {
	box-sizing: inherit;
	-webkit-tap-highlight-color: transparent !important;
	-webkit-touch-callout: none;
}

.prevent-scrolling {
	overflow: hidden;
}

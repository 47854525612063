
					@use 'sass:math';
					@import "@/styles/globals.scss";
					

.layout-default {
	width: 100%;
	padding: 0 4.8rem;
	background-color: $color-light;

	@include max-lg-and-portrait {
		padding: 0 3rem;
	}

	.app-content {
		display: flex;
		grid-gap: 0 2.4rem;
		width: 100%;
		min-height: $view-height;
		padding-top: $header-height;

		&--has-sidebar,
		&--has-sidebar-small {
			@include min-md {
				display: grid;
			}
		}

		&--has-sidebar {
			grid-template-areas: 'sidebar main main';
			grid-template-columns: repeat(3, 1fr);
		}

		&--has-sidebar-small {
			grid-template-areas: 'sidebar main main main';
			grid-template-columns: repeat(4, 1fr);

			@include max-md-and-portrait {
				/* prettier-ignore */
				grid-template-areas: 'sidebar sidebar'
					'main main';
				grid-template-columns: repeat(2, 1fr);
				grid-template-rows: max-content;
			}
		}
	}

	.app-sidebar {
		grid-area: sidebar;

		&__sticky-wrapper {
			width: 100%;
		}

		&__inner {
			height: 100%;
		}
	}

	.app-main {
		grid-area: main;
		width: 100%;
	}
}


					@use 'sass:math';
					@import "@/styles/globals.scss";
					

.heading {
	padding: 2rem 0;
	border-bottom: 1px solid $color-primary;
	h1 {
		@include heading-3;
	}
	h2 {
		@include heading-4;
		padding: 1.5rem 0;
	}
}


					@use 'sass:math';
					@import "@/styles/globals.scss";
					

.nav-tabs {
	$self: &;

	position: relative;
	border-radius: 1.2rem;
	background: $color-light;

	&__list {
		display: flex;
		height: 4.8rem;
		padding: 0.4rem;
		position: relative;
		z-index: 10;

		@include max-lg-and-portrait {
			height: 4.4rem;
		}
	}

	&__item {
		flex: 1;
		color: $color-gray;
		text-align: center;

		&.is-active,
		&--is-active {
			#{$self}__link {
				color: $color-light;
				transition: color 300ms ease;
			}
		}

		&.is-disabled,
		&--is-disabled {
			#{$self}__link {
				color: rgba($color-gray, 0.5);
				pointer-events: none;
			}
		}
	}

	&__pill {
		height: 4rem;
		position: absolute;
		top: 0.4rem;
		left: 0;
		z-index: 1;
		transform: translateX(0.4rem);
		border-radius: 0.8rem;
		background: $color-dark;

		@include max-lg-and-portrait {
			height: 3.6rem;
		}

		&.is-hidden {
			display: none;
		}
	}

	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		padding: 0.2rem 0.5rem;
		color: inherit;
		color: rgba($color-dark, 0.8);
		font-size: 1.5rem;
		font-weight: 500;
		line-height: 1.15;
		text-align: center;
		text-decoration: none;
		transition: color 300ms ease-out;
		opacity: 1;
		border-radius: 0.8rem;

		&:hover {
			color: rgba($color-dark, 1);
		}
	}
}


					@use 'sass:math';
					@import "@/styles/globals.scss";
					

.base-datepicker {
	$self: &;

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 1.4rem;
		color: $color-dark;
	}

	&__day {
		padding: 8px 10px;
		color: $color-gray;

		&--selected {
			#{$self}__day-button {
				color: $color-light;
				border-radius: 8px;
				background-color: $color-dark;
			}
		}

		&--is-disabled {
			#{$self}__day-button {
				pointer-events: none;
			}
		}
	}

	&__day-button {
		width: 32px;
		height: 32px;
		font-size: 1.8rem;
		text-align: center;
	}

	&__previous-month,
	&__next-month {
		width: 40px;
		height: 40px;

		> svg {
			width: 10px;
			height: 20px;
		}
	}
}

$color-primary: #222;
$color-black: #000;
$color-dark: #201e1e;
$color-link: #605f5f;
$color-gray: #7b7b80;
$color-medium: #cdcdcd;
$color-medium-light: #e3e3e3;
$color-light: #f1f1f1;
$color-light-2: #f9f9f9;
$color-white: #fff;
$color-error: #c1272d;
$font-size-body: 1.8rem;
$font-size-small: 1.6rem;
$font-size-label: 1.2rem;
$font-size-h1: 4.6rem;
$font-size-h2: 4rem;
$font-size-h3: 2.8rem;
$font-size-h4: 2.4rem;
$font-size-input: 2.8rem;
$breakpoints: (
	xs: 320px,
	sm: 480px,
	md: 768px,
	lg: 1024px,
	xl: 1200px,
	shd: 1440px,
	hd: 1920px,
);
$header-height: var(--header-height);
$view-height: calc(var(--vh, 1vh) * 100);
$landing-bg-transition: 700ms $easeOutQuart;

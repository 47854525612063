
					@use 'sass:math';
					@import "@/styles/globals.scss";
					

.form-item {
	display: flex;
	flex-direction: column;
	margin-bottom: 3rem;
	position: relative;
	transition: all 300ms $easeOutQuad;
	transition-property: margin-bottom, opacity;

	&--below {
		padding-bottom: 2rem;
	}

	&:last-child {
		margin-bottom: 0;
	}

	&__label {
		@include small-text;
		display: flex;
		color: rgba($color-dark, 0.8);
	}

	&__input-wrapper {
		width: 100%;
		margin-top: auto;

		input:not([type='checkbox']),
		textarea,
		.v-select {
			width: 100%;
		}

		input.vs__search {
			width: 0;
		}

		textarea {
			min-height: 17.4rem;
		}

		input {
			@include max-lg-and-portrait {
				font-size: 2.4rem;
			}
		}

		.base-select {
			margin-top: 1.2rem;
		}
	}

	&__error {
		margin-top: 1.6rem;
		position: absolute;
		top: 100%;
		right: 0;
		left: 0;
		font-size: 1.5rem;
		text-align: right;
	}

	&--has-error {
		margin-bottom: 3.5rem;

		&:not(&--has-empty-input) {
			input,
			textarea {
				color: $color-error;
				border-color: $color-error;
			}
		}
	}
}

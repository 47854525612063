
					@use 'sass:math';
					@import "@/styles/globals.scss";
					

.slider {
	height: auto;

	.swiper {
		.swiper-pagination {
			width: auto;
			height: 2.4rem;
			margin: 0;

			@include max-md-and-portrait {
				right: 0;
			}

			&--align-top-right {
				top: 0;
				right: -0.5rem;
				left: auto;
			}

			&--align-bottom-center {
				top: auto;
				bottom: 1rem;
				left: 50%;
				transform: translateX(-50%);
			}
		}

		.swiper-pagination-bullet {
			width: 1.2rem;
			height: 1.2rem;
			margin: 0 0.6rem;
			font-size: 12px;
			transition: 0.2s ease;
			opacity: 1;
			border: 1px solid $color-dark;
			border-radius: 50%;
			outline: none;
			background: transparent;

			@include max-md-and-portrait {
				margin: 0 0.7rem;
			}

			&:only-child {
				visibility: hidden;
				pointer-events: none;
			}
		}

		.swiper-pagination-bullet-active {
			width: 4.2rem;
			height: 1.2rem;
			border-radius: 0.8rem;
			background-color: $color-dark;

			@include max-md-and-portrait {
				width: 4.2rem;
				height: 1.2rem;
			}
		}
	}
}


					@use 'sass:math';
					@import "@/styles/globals.scss";
					

.base-menu-item {
	display: block;

	&__activator {
		display: flex;
		padding: 1.6rem 0;
		text-decoration: none;
	}

	> button {
		width: 100%;
	}
}

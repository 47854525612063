
					@use 'sass:math';
					@import "@/styles/globals.scss";
					

.base-radio {
	$self: &;

	@include small-text;

	border: none;

	&__legend {
		margin-bottom: 4rem;
	}

	&__wrapper {
		display: flex;
		align-items: center;
		position: relative;

		&:not(:last-child) {
			margin-bottom: 3.2rem;
		}

		&:hover,
		&:focus,
		&:active {
			#{$self}__dot {
				&::after {
					opacity: 1;
					background-color: $color-dark;
				}
			}
		}
	}

	&__input {
		width: 0;
		height: 0;
		margin: 0;
		position: absolute;
		opacity: 0;

		&:checked {
			+ #{$self}__dot {
				&::before {
					transform: translate(-50%, -50%) scale(1);
					background-color: $color-dark;
				}

				&::after {
					opacity: 1;
					background-color: $color-white;
				}
			}
		}
	}

	&__dot {
		min-width: 4rem;
		height: 4rem;
		position: relative;
		transition: border-color 0.2s $easeOutQuad;
		border: 1px solid $color-dark;
		border-radius: 50%;

		&::before {
			content: '';
			width: calc(100% + 2px);
			height: calc(100% + 2px);
			position: absolute;
			top: 50%;
			left: 50%;
			transition: transform 0.2s $easeOutQuad;
			transform: translate(-50%, -50%) scale(0);
			border-radius: 50%;
		}

		&::after {
			content: '';
			width: 0.8rem;
			height: 0.8rem;
			position: absolute;
			top: 50%;
			left: 50%;
			transition: opacity 0.2s $easeOutQuad;
			transform: translate(-50%, -50%);
			opacity: 0;
			border-radius: 50%;
			background-color: white;
		}
	}

	&__label {
		padding-left: 2.4rem;
		transition: color 0.3s $easeOutQuad;
	}

	&--is-checked {
		#{$self}__input:not(:checked) {
			~ #{$self}__dot {
				border-color: $color-gray;

				&::after {
					background-color: $color-gray;
				}
			}

			~ #{$self}__label {
				color: $color-gray;
			}
		}
	}
}


					@use 'sass:math';
					@import "@/styles/globals.scss";
					

.app-loader {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999999999;
	background: $color-light;

	&__logo {
		$logo_size: 5.6rem;

		width: $logo_size;
		height: $logo_size;
	}

	&__loader {
		@include dropshadow-default;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 16rem;
		height: 16rem;
		position: relative;
		border-radius: 1.2rem;
		background: $color-white;

		svg {
			display: block;
			margin-top: -1rem;
		}
	}

	&__bar {
		$_dot_size: 0.7rem;
		$_dot_anim: animation-name-auto('dot');
		$_dot_color: $color-gray;
		$_dot_min_alpha: 0.4;

		margin-left: (-0.5 * $_dot_size);
		bottom: 2.5rem;
		left: 50%;

		&,
		&::before,
		&::after {
			width: $_dot_size;
			height: $_dot_size;
			position: absolute;
			animation: #{$_dot_anim} 1s infinite linear;
			border-radius: 50%;
			background: $color-medium;
		}

		&::before,
		&::after {
			content: '';
			display: block;
		}

		& {
			animation-delay: 100ms;
		}

		&::before {
			right: $_dot_size * 2;
			animation-delay: 0ms;
		}

		&::after {
			left: $_dot_size * 2;
			animation-delay: 200ms;
		}

		@keyframes #{$_dot_anim} {
			0% {
				background: rgba($_dot_color, $_dot_min_alpha);
			}

			20% {
				background: rgba($_dot_color, 1);
			}

			40% {
				background: rgba($_dot_color, $_dot_min_alpha);
			}

			100% {
				background: rgba($_dot_color, $_dot_min_alpha);
			}
		}
	}
}

.app-loader-fade-enter-active,
.app-loader-fade-leave-active {
	transition: opacity 0.3s ease;
}

.app-loader-fade-enter-from,
.app-loader-fade-leave-to {
	opacity: 0;
}


					@use 'sass:math';
					@import "@/styles/globals.scss";
					

.page-header {
	$self: &;

	max-width: 38rem;

	@include max-tablet-portrait {
		display: grid;
		grid-template-columns: 5fr 7fr;
		max-width: 100%;
	}

	&--has-no-description {
		@include max-tablet-portrait {
			display: block;
		}
	}

	&__heading {
		@include heading-1;
		display: flex;
		align-items: flex-end;

		@include max-tablet-portrait {
			align-items: flex-start;
			padding-right: 2.2rem;
		}

		.vue-content-placeholders-text {
			padding: 0.65rem 0;
		}

		.vue-content-placeholders-text__line {
			height: 4.5rem;
		}
	}

	&__description {
		margin-top: 2.4rem;
		color: $color-gray;
		font-weight: 500;
		line-height: 1.28;

		@include max-tablet-portrait {
			margin-top: 0;
		}
	}

	&__buttons {
		.base-button {
			animation: animation-name-auto('in') 300ms forwards $easeOutQuad;

			&:first-child {
				margin-top: 4rem;
			}

			&:not(:last-child) {
				margin-bottom: 2.4rem;
			}

			@keyframes #{animation-name-auto('in')} {
				from {
					transform: translate(20px);
					opacity: 0;
				}

				to {
					transform: translateX(0);
					opacity: 1;
				}
			}
		}
	}

	&--reserve-vertical-space {
		#{$self}__heading {
			min-height: 2 * percent-size($font-size-h1, 120%);

			@include max-lg-and-portrait {
				min-height: auto;
			}
		}
	}

	.vue-content-placeholders-text__line {
		background: $color-medium;
	}
}


					@use 'sass:math';
					@import "@/styles/globals.scss";
					

.view-login {
	display: flex;
	align-items: center;

	&__form {
		@include max-md-and-portrait {
			display: grid;
			grid-template-columns: 1fr 1fr;
		}

		.form__header {
			padding: 0.5rem 0 2rem;

			@include max-md-and-portrait {
				padding: 0;
			}

			@include max-xl {
				padding: 0.5rem 0 4rem;
			}
		}

		.form__content {
			padding: 2rem 0;

			@include max-md-and-portrait {
				padding: 0;
			}
		}
	}
}
